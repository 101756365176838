











































import {Component, Mixins} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import GasbotFaqCard from '@/components/gasbot/GasbotFaqCard.vue'
import {GasbotFaqCards} from '@/model/resource/GasbotFaqCards'

@Component({
  components: {GasbotFaqCard},
})
export default class GasbotFaq extends Mixins(MixinScreenSize) {
  currentlyActiveIndex = null
  list = (this.$t(
    'components.gasbotView.gasbotFAQ.items'
  ) as unknown) as GasbotFaqCards[]
}
