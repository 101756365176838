































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component
export default class GasbotFaqCard extends Mixins(MixinScreenSize) {
  @Prop({type: String, required: true}) question!: String
  @Prop({type: String, required: true}) answer!: String
  @Prop({type: Number || null, default: null}) activeIndex!: number | null
  @Prop({type: Number || null, default: null}) itemIndex!: number | null
  emailClass = (email: string) =>
    `<a class="text-ocean-green" href='mailto:${email}'>${email}</a>`
  emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi

  get isItemActive() {
    return this.activeIndex === this.itemIndex
  }

  answerFormatter(answer: string): string {
    let formatedAnswer = answer.replace('\n', '<br>')
    answer.match(this.emailRegex)?.forEach(email => {
      formatedAnswer = formatedAnswer.replace(email, this.emailClass(email))
    })
    return formatedAnswer
  }

  handleClick() {
    if (this.isItemActive) {
      this.$emit('update:itemIndex', null)
    } else {
      this.$emit('update:itemIndex', this.itemIndex)
    }
  }
}
