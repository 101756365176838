






























import {Component, Mixins} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import GasbotCommandsCard from '@/components/gasbot/GasbotCommandsCard.vue'
import {GasbotCommand} from '@/model/resource/GasbotCommand'
@Component({
  components: {GasbotCommandsCard},
})
export default class GasbotCommands extends Mixins(MixinScreenSize) {
  list = (this.$t(
    'components.gasbotView.commands.items'
  ) as unknown) as GasbotCommand[]

  formatDescription(description: string): string {
    return description.replace('/command', '<strong>/command</strong>')
  }
}
