import { render, staticRenderFns } from "./GasbotSupportedTokens.vue?vue&type=template&id=8921fa30&scoped=true"
import script from "./GasbotSupportedTokens.vue?vue&type=script&lang=ts"
export * from "./GasbotSupportedTokens.vue?vue&type=script&lang=ts"
import style0 from "./GasbotSupportedTokens.vue?vue&type=style&index=0&id=8921fa30&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8921fa30",
  null
  
)

export default component.exports