











import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component
export default class GasbotCommandsCard extends Mixins(MixinScreenSize) {
  @Prop({type: String, required: true}) command!: String
  @Prop({type: String, required: true}) description!: String

  formatCommand(command: String): String {
    return `/${command}`
  }
}
